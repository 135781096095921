<template>
  <div class="business__type">
    <h2 class="text-center">What is your business model?</h2>
    <v-row class="mt-3">
      <v-col v-for="(bType, index) in types" :key="index" cols="6" md="4">
        <v-hover v-slot="{ hover }" open-delay="200">
          <v-card
            :class="{
              'on-hover': hover,
              'active-business__type': typeModel.id === bType.id,
            }"
          >
            <v-card-text @click="chooseType(bType)">
              {{ bType.title }}
            </v-card-text>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
    <div class="my-5 d-flex">
      <v-btn @click="$parent.$parent.$parent.e1 = 1">Back</v-btn>
      <v-btn :disabled="disabledBtn" class="ml-auto" @click="nextScreen()" color="primary">Next</v-btn>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    step: {
      type: String,
      default: '',
    },
    /**
     * Callback
     */
    callback: {
      type: Function,
      default(data) {},
    },
  },
  data() {
    return {
      isTrademark: false,
      types: [
        { id: 1, title: 'DROPSHIP', code: 'dropship', isTrademark: false },
        { id: 2, title: 'POD (TM or Non-TM)', code: 'pod' },
        { id: 3, title: 'Repica (Not available now)', code: 'dropship', isTrademark: true },
      ],
      items: [
        { title: 'Trademark', value: 'trademark' },
        { title: 'No Trademark', value: 'noTrademark' },
      ],
      typeModel: {
        title: '',
        code: '',
        isTrademark: false,
      },
    };
  },
  created() {},
  mounted() {
    this.typeModel = { ...this.types[0] };
  },
  computed: {
    disabledBtn() {
      if (this.typeModel?.code) {
        return false;
      }
      return true;
    },
  },
  methods: {
    chooseType(type) {
      this.typeModel = type;
    },
    nextScreen() {
      this.callback(this.typeModel);
    },
  },
  watch: {
    '$parent.$parent.$parent.params.businessType': function() {},
  },
};
</script>
<style lang="scss">
.business__type {
  width: 100%;
  padding: 5px;
  .on-hover {
    background-color: var(--v-primary-base) !important;
    cursor: pointer !important;
    .v-card__text {
      color: white !important;
    }
  }
  .active-business__type {
    background-color: var(--v-primary-base) !important;
    .v-card__text {
      color: white !important;
    }
  }
}
</style>
