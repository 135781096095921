<template>
  <div style="width: 100%; height: 100vh" v-if="isLoading" class="d-flex justify-center align-center">
    <page-loader />
  </div>
  <div v-else class="survey background">
    <v-container>
      <v-row style="height: 100vh;" class="align-center justify-center">
        <v-col class="content-survey">
          <ConfigStore :callback="configStore" />
          <v-stepper v-if="false" v-model="e1" alt-labels>
            <v-stepper-header v-if="false">
              <v-stepper-step :complete="e1 > 3" step="1"> Setup your store </v-stepper-step>
              <v-divider :class="e1 > 3 ? 'complete' : ''"></v-divider>
              <v-stepper-step :complete="e1 > 4" step="2"> Customize your store </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <!-- Information user -->
              <v-stepper-content step="1">
                <ConfigStore :callback="configStore" />
              </v-stepper-content>
              <v-stepper-content step="2">
                <Notification type="error" :messages="errors" v-if="errors.length > 0" />
                <customize-store :store="userStore" :callback="submit" />
              </v-stepper-content>

              <v-stepper-content step="3">
                <div v-if="!isLoading" class="text-center mt-5">
                  <v-icon size="80" color="success">fas fa-check-circle</v-icon>
                  <h2 class="mt-5">Success, your store is ready to go!</h2>
                </div>
                <div v-else class="text-center mt-5">
                  <page-loader />
                </div>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Infor from './Information';
import CustomizeStore from './CustomizeStore';
import BusinessType from './BusinessType';
import ProductNike from './ProductNike';
import Subscription from './Subscription';
import { authService } from '@/apis/auth.s';
import { userService } from '@/apis/user.s';
import { storeService } from '@/apis/store.s';
import Notification from '@/components/Notification';
import STORAGE_NAME from '@/const/storage';
import { widgetApi } from '@/apis/widget';
import { log } from 'handlebars';
import ConfigStore from './ConfigStore.vue';

export default {
  components: {
    // Infor,
    CustomizeStore,
    // BusinessType,
    Notification,
    // Subscription,
    ConfigStore,
  },
  data() {
    let defaultConfig = {
      activated: false,
      device: 'desktop',
      widgetType: 'bestSeller',
      widgetCustomize: {
        productPrice: {
          align: 'center',
          bold: false,
          fontColor: '#000000',
          fontSize: '14px',
          italic: false,
          underline: false,
          textTitle: 'Title ',
        },
        productName: {
          align: 'center',
          bold: false,
          fontColor: '#000000',
          fontSize: '14px',
          italic: false,
          underline: false,
          textTitle: 'Programmer Label Personalized',
        },
        place: {
          cartPage: false,
          categoryPage: false,
          homePage: false,
          productPage: true,
          page: {
            collectionIds: [],
            productIds: [],
            selectType: 'products',
            isShowAtSpecificPage: false,
          },
        },
        header: {
          align: 'center',
          bold: false,
          fontColor: '#000000',
          fontSize: '25px',
          italic: false,
          underline: false,
          textTitle: 'Handpicked products just for you',
        },
        addCartButton: {
          enabled: true,
          callToAction: {
            align: 'center',
            bold: false,
            fontColor: '#000000',
            fontSize: '12px',
            italic: false,
            underline: false,
            textTitle: 'Title ',
          },
        },
        layout: '1',
        numberOfProducts: {
          productsPerSlide: 6,
          productsToBeShown: 12,
        },
      },
    };
    return {
      defaultConfig,
      e1: 1,
      params: {
        storeContact: { storeCountry: 'Vietnam', phone: '' },
        isTrademark: false,
        businessType: 'dropship',
        paymentChoice: {
          type: 'latte',
          servicePackage: {},
        },
        importFromStore: {
          storeId: '',
          doNotImport: false,
          importItems: {
            themesChecked: true,
            collectionChecked: true,
            navigationChecked: true,
            pagesChecked: true,
            appsChecked: true,
            legalPagesChecked: true,
            shippingCurrencyChecked: true,
            productsChecked: true,
          },
        },
        productNiche: 'Hardware',
        domainRequire: null,
        logoPath: null,
        theme: 3,
      },
      stores: [],
      errors: [],
      store: { name: '' },
      user: {
        firstName: '',
        lastName: '',
        phone: '',
      },
      isLoading: false,
      userStore: null,
    };
  },
  async mounted() {
    await this.loadStores();
    this.createAllAfterCreateStore(this.userStore.id, this.userStore.storeDetail.defaultSystemDomain);
  },
  methods: {
    async createAllAfterCreateStore(storeId, defaultSystemDomain) {
      let [cartRecommend, moreCollection, bestSeller, recentView, alsoBought, handpickedProduct, pickForYou] = [
        {
          ...this.defaultConfig,
          widgetType: 'cartRecommend',
          activated: false,
          widgetCustomize: {
            ...this.defaultConfig.widgetCustomize,
            header: {
              ...this.defaultConfig.widgetCustomize.header,
              textTitle: 'Cart recommendations',
            },
            place: {
              cartPage: true,
              homePage: true,
              productPage: true,
              page: {
                collectionIds: [],
                productIds: [],
                selectType: 'products',
                isShowAtSpecificPage: false,
              },
            },
          },
        },
        {
          ...this.defaultConfig,
          widgetType: 'moreCollection',
          activated: false,
          widgetCustomize: {
            ...this.defaultConfig.widgetCustomize,
            header: {
              ...this.defaultConfig.widgetCustomize.header,
              textTitle: 'More from {{collection_name}}',
            },
            place: {
              cartPage: true,
              homePage: false,
              productPage: true,
              page: {
                collectionIds: [],
                productIds: [],
                selectType: 'products',
                isShowAtSpecificPage: false,
              },
            },
          },
        },
        {
          ...this.defaultConfig,
          widgetType: 'bestSeller',
          activated: false,
          widgetCustomize: {
            ...this.defaultConfig.widgetCustomize,
            header: {
              ...this.defaultConfig.widgetCustomize.header,
              textTitle: 'Store best sellers',
            },
            place: {
              homePage: true,
              cartPage: false,
              productPage: false,
              page: {
                collectionIds: [],
                productIds: [],
                selectType: 'products',
                isShowAtSpecificPage: false,
              },
            },
          },
        },
        {
          ...this.defaultConfig,
          widgetType: 'recentView',
          activated: false,
          widgetCustomize: {
            ...this.defaultConfig.widgetCustomize,
            header: {
              ...this.defaultConfig.widgetCustomize.header,
              textTitle: 'Recently viewed & featured recommendations',
            },
            place: {
              cartPage: true,
              homePage: true,
              productPage: true,
              page: {
                collectionIds: [],
                productIds: [],
                selectType: 'products',
                isShowAtSpecificPage: false,
              },
            },
          },
        },
        {
          ...this.defaultConfig,
          widgetType: 'alsoBought',
          activated: false,
          widgetCustomize: {
            ...this.defaultConfig.widgetCustomize,
            header: {
              ...this.defaultConfig.widgetCustomize.header,
              textTitle: 'Who bought this also bought',
            },
          },
        },
        {
          ...this.defaultConfig,
          widgetType: 'handpickedProduct',
          activated: false,
          widgetCustomize: {
            ...this.defaultConfig.widgetCustomize,
            place: {
              productPage: true,
              page: {
                collectionIds: [],
                productIds: [],
                selectType: 'products',
                isShowAtSpecificPage: false,
              },
            },
          },
        },
        {
          ...this.defaultConfig,
          widgetType: 'pickForYou',
          activated: false,
          widgetCustomize: {
            ...this.defaultConfig.widgetCustomize,
            header: {
              ...this.defaultConfig.widgetCustomize.header,
              textTitle: 'Pick For You',
            },
            showWith: 'sameTitle',
            place: {
              productPage: true,
              page: {
                collectionIds: [],
                productIds: [],
                selectType: 'products',
                isShowAtSpecificPage: false,
              },
            },
          },
        },
      ];
      //cartRecommend

      let res = await widgetApi.createAllAfterCreateStore(
        {
          productWidgets: [
            cartRecommend,
            moreCollection,
            bestSeller,
            recentView,
            alsoBought,
            handpickedProduct,
            pickForYou,
          ],
        },
        storeId,
        defaultSystemDomain,
      );
    },
    /*
    Set infomation
    */
    setInfor(data) {
      this.e1 = 2;
      this.params.storeContact.storeCountry = data.country.name;
      this.params.storeContact.phone = data.number;
      this.user.firstName = data.firstName;
      this.user.lastName = data.lastName;
      this.user.phone = data.number;
    },
    /*
    Set business type
    */
    setBusinessType(data) {
      this.params.businessType = data.code;
      this.params.isTrademark = data?.isTrademark ? data?.isTrademark : false;
      this.e1 = 3;
    },
    /*
    Payment Choice
    */
    setPaymentChoice(data) {
      this.params.paymentChoice.type = data.name;
      this.e1 = 4;
    },
    /*
    Set product nike
    */
    setProductNike(data) {
      this.e1 = 4;
      this.params.productNiche = data;
    },

    async loadStores() {
      try {
        let storesData = await userService.getStores();
        if (storesData) {
          window.localStorage.setItem(STORAGE_NAME.STORE_DOMAIN, storesData.data[0].storeDetail.defaultSystemDomain);
          this.userStore = storesData.data[0] || null;
        }
      } catch (error) {
        console.log(error);
      }
    },
    /*
    Submit survey
    */
    async getStores() {
      try {
        let data = await userService.getStores();
        this.stores = data.data.reverse().filter(store => store !== null);
      } catch (error) {
        console.log(error.response);
      }
    },
    async submit(data) {
      this.isLoading = true;
      let vm = this;

      this.params.themeCustomizePrimaryColor = data.color;
      this.params.themeCustomizeFont = data.font;
      this.params.mmoExperience = data.mmoExperience;
      this.params.importFromStore = data.importFromStore;
      this.params.importFromStore.doNotImport = !data.importFromStore.storeId ? true : false;

      this.errors = [];
      try {
        if (this.$route.query.register != '') {
          let surveyData = await storeService.survey(this.params);
          // let access_token = localStorage.getItem('access-token');
          // let refresh_token = localStorage.getItem('refresh-token');
          // let defaultSystemDomain = this.$route.query.defaultSystemDomain;
          // let storeId = localStorage.getItem('store-id');
          // let storeId222 = this.$route.query.storeId;
          // console.log('access_token', access_token);
          // console.log('refresh_token', refresh_token);
          // console.log('storeId', storeId);
          // console.log('storeId222', storeId222);
          // console.log('defaultSystemDomain', defaultSystemDomain);
          await setTimeout(() => {
            this.isLoading = true;
          }, 500);
          await setTimeout(async function() {
            let access_token = localStorage.getItem('access-token');
            let refresh_token = localStorage.getItem('refresh-token');
            let defaultSystemDomain = localStorage.getItem('defaultSystemDomain');
            let storeId = localStorage.getItem('store-id');
            let url = 'https://' + defaultSystemDomain + '/admin/';

            window.location.href =
              url +
              '?access_token=' +
              access_token +
              '&refresh_token=' +
              refresh_token +
              '&system_domain=' +
              defaultSystemDomain +
              '&r_domain=' +
              defaultSystemDomain +
              '&store_id=' +
              storeId;
          }, 3000);
        } else {
          // submit survey
          let surveyData = await storeService.survey(this.params);
          // update user infor
          let user = await userService.update(this.user);
          await vm.createAllAfterCreateStore(vm.userStore.id, vm.userStore.storeDetail.defaultSystemDomain);
          this.e1 = 5;
          await setTimeout(() => {
            this.isLoading = true;
          }, 500);
          await setTimeout(async function() {
            let access_token = window.localStorage.getItem(STORAGE_NAME.ACCESS_TOKEN);
            let refresh_token = window.localStorage.getItem(STORAGE_NAME.REFRESH_TOKEN);
            let url = 'https://' + vm.userStore.storeDetail.defaultSystemDomain + '/admin/';
            window.location.href =
              url +
              '?access_token=' +
              access_token +
              '&refresh_token=' +
              refresh_token +
              '&system_domain=' +
              vm.userStore.storeDetail.defaultSystemDomain +
              '&r_domain=' +
              vm.userStore.storeDetail.defaultSystemDomain +
              '&store_id=' +
              vm.userStore.id;
          }, 3000);
        }
      } catch (error) {
        this.errors = error;
        this.isLoading = false;
      }
    },
    // Config Store
    configStore({ storeDomain, favicon, theme, paymentChoice }) {
      this.params.domainRequire = storeDomain;
      this.params.logoPath = favicon.src;
      this.params.theme = theme;
      this.params.paymentChoice.type = paymentChoice;
      this.submit({
        color: '#0061AF',
        font: 'Abel',
        mmoExperience: `I'm a newbie and have no experience in selling online`,
        importFromStore: {
          storeId: '',
          doNotImport: false,
          importItems: {
            themesChecked: true,
            collectionChecked: true,
            navigationChecked: true,
            pagesChecked: true,
            appsChecked: true,
            legalPagesChecked: true,
            shippingCurrencyChecked: true,
            productsChecked: true,
          },
        },
      });
    },
  },
};
</script>

<style lang="scss">
.survey {
  height: 100%;
  background-color: white;
  .content-survey {
    max-width: 900px;
  }

  .complete {
    border-color: var(--v-primary-base) !important;
  }

  .v-stepper__label {
    text-align: center !important;
  }
}

.v-stepper {
  box-shadow: none;
}

.v-stepper__header {
  box-shadow: none;
}

.v-stepper__wrapper {
  overflow: unset !important;
}
</style>
