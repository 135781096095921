<template>
  <v-form>
    <h2 class="text-center mb-12">Let's setup your store</h2>
    <v-card class="px-4 py-3 mb-6" outlined>
      <v-row class="mb-4">
        <v-col cols="12" md="6" class="pb-0"
          ><label class="font-weight-medium"> Choose your new domain </label>
          <p class="mb-0 grey--text" style="font-size: 14px">Enter the domain you want to connect</p></v-col
        >
        <v-col cols="12" md="6">
          <v-text-field
            autocomplete="off"
            placeholder="e.g. example.com"
            append-icon="mdi-help-circle-outline"
            class="store-domain-input"
            v-model.trim="form.storeDomain"
            :error-messages="errorMessageStoreDomain"
            @blur="validateDomain"
            @change="onSaveDomain"
            @click:append="domainTutorialDialog = true"
          ></v-text-field>
          <p v-if="isDomainReady && isDomain(form.storeDomain)" class="mb-0 success--text">
            <i class="mdi mdi-check-circle-outline mr-1"></i>Ready to use
          </p>
        </v-col>
      </v-row>
      <v-row class="mb-4">
        <v-col cols="12" md="6" class="pb-0">
          <label class="font-weight-medium"> Logo </label>
          <p class="mb-0 grey--text" style="font-size: 14px">Upload your store's logo</p>
        </v-col>
        <v-col cols="12" md="6">
          <UploadOneImage :image="form.favicon" @callback="getImageUpLoad" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" class="pb-0">
          <label class="font-weight-medium"> Choose your payment </label>
        </v-col>
        <v-col cols="12" md="6">
          <v-chip-group v-model="form.paymentChoice" color="primary" mandatory>
            <v-chip label active-class="primary" value="latte">Platform Payment</v-chip>
            <v-chip label active-class="primary" value="seller">Sellers Payment</v-chip>
          </v-chip-group>
        </v-col>
      </v-row>
      <v-row v-if="false" class="mb-4">
        <v-col cols="12" md="6" class="pb-0">
          <label class="font-weight-medium"> Theme Store </label>
          <p class="mb-0 grey--text" style="font-size: 14px">Choose theme for your store</p>
        </v-col>

        <v-col cols="12" md="6">
          <v-row dense>
            <v-col cols="6">
              <v-btn width="100%" depressed :color="form.theme == 3 ? 'primary' : 'default'" @click="form.theme = 3"
                >Green Health
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn width="100%" depressed :color="form.theme == 4 ? 'primary' : 'default'" @click="form.theme = 4">
                Blue Tech
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn width="100%" depressed :color="form.theme == 5 ? 'primary' : 'default'" @click="form.theme = 5">
                Mint Shop
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn width="100%" depressed :color="form.theme == 6 ? 'primary' : 'default'" @click="form.theme = 6">
                Vegashine
              </v-btn>
            </v-col>
          </v-row>
          <ul class="list-theme">
            <li @click="form.theme = 3" v-if="form.theme === 3" :class="{ actived: form.theme === 3 }">
              <div class="image-wrapper">
                <img
                  src="https://minio.dcomcy.com/img/400/500/resize/static/21-07-2023/2.png"
                  alt="Green Health Theme"
                />
              </div>
            </li>
            <li v-if="form.theme === 4" @click="form.theme = 4" :class="{ actived: form.theme === 4 }">
              <div class="image-wrapper">
                <img src="https://minio.dcomcy.com/img/400/500/resize/static/21-07-2023/1.png" alt="Blue Tech Theme" />
              </div>
            </li>
            <li v-if="form.theme === 5" @click="form.theme = 5" :class="{ actived: form.theme === 5 }">
              <div class="image-wrapper">
                <img src="@/assets/images/mint-shop.png" alt="Mint Shop" />
              </div>
            </li>
            <li v-if="form.theme === 6" @click="form.theme = 5" :class="{ actived: form.theme === 5 }">
              <div class="image-wrapper">
                <img src="@/assets/images/vegashine-theme.png" alt="Vegashine" />
              </div>
            </li>
          </ul>
        </v-col>
      </v-row>
    </v-card>
    <div class="d-flex justify-end" style="gap: 8px">
      <v-btn color="primary" :loading="isLoading" @click="nextStep" depressed> Finish </v-btn>
    </div>
    <v-dialog v-model="domainTutorialDialog" width="500">
      <v-card>
        <v-card-title class="text-h5"> Help (Hướng dẫn) </v-card-title>
        <v-card-text>
          <p class="mb-2">Cửa hàng trực tuyến của bạn cần có một tên miền để khách hàng có thể truy cập được.</p>
          <ul class="mb-4">
            <li>
              <strong>Nếu bạn chưa có domain:</strong> hãy điền domain phù hợp, chúng tôi sẽ mua và gắn vào cửa hàng.
              *Nhớ kiểm tra domain còn mua được không
            </li>
            <li>
              <strong>Nếu bạn đã có sẵn tên miền:</strong> để trống tên miền và nhắn đội hỗ trợ qua nick Telegram
              @dcomcy_tech để được hỗ trợ sẽ giúp bạn gắn tên miền vào cửa hàng.
            </li>
          </ul>
          <p class="mb-2">Your online store needs a domain that helps your customers access it.</p>
          <ul>
            <li>
              <strong>If you do not own a domain:</strong> Please think of a name that represents your store and make
              sure that the domain is still available via namecheap.com*. Then we will help you buy and add the domain
              to your store.
            </li>
            <li>
              <strong>If you already own a domain:</strong> and want to transfer it to Dcomcy, please contact our
              support via Telegram account @dcomcy_tech, we will help you to connect that domain to your store.
            </li>
          </ul>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="domainTutorialDialog = false"> Okay </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
import { domainService } from '@/apis/domain.s';

export default {
  props: {
    callback: {
      type: Function,
      default(data) {},
    },
  },
  data() {
    return {
      form: {
        storeDomain: null,
        favicon: { src: '', position: 1 },
        theme: 6,
        paymentChoice: 'latte',
      },
      isLoading: false,
      isValidDomain: true,
      errorMessageStoreDomain: null,
      domainTutorialDialog: false,
      isDomainReady: false,
    };
  },
  methods: {
    getImageUpLoad(image) {
      this.form.favicon = image;
      this.$forceUpdate();
    },
    isDomain(domain) {
      if (!domain) return false;
      const regex = /(?:[\w-]+\.)+[\w-]+/;
      return regex.test(domain);
    },
    validateDomain() {
      if (!this.form.storeDomain) {
        this.isValidDomain = true;
        this.isDomainReady = true;
      } else {
        this.isValidDomain = this.isDomain(this.form.storeDomain);
        if (!this.isValidDomain) {
          this.errorMessageStoreDomain = 'Your domain is not valid';
          this.isDomainReady = false;
        }
      }
    },
    nextStep() {
      this.isLoading = true;
      this.validateDomain();
      if (this.isValidDomain && this.isDomainReady) {
        this.callback(this.form);
      }
      this.isLoading = false;
    },
    skipStep() {
      this.callback({ storeDomain: null, favicon: { src: '', position: 1 }, theme: 6 });
    },
    async onSaveDomain() {
      this.validateDomain();
      if (this.form.storeDomain && this.isValidDomain)
        try {
          this.isLoading = true;
          const { data } = await domainService.checkDomain(this.form.storeDomain);
          if (data?.status) {
            this.errorMessageStoreDomain = null;
            this.isDomainReady = true;
          } else {
            this.isValidDomain = false;
            this.isDomainReady = false;
            this.errorMessageStoreDomain = data.msg;
          }
        } catch (error) {
          console.log('Error: ', error);
        } finally {
          this.isLoading = false;
        }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';

ul.list-theme {
  padding-left: 0;
  margin-top: 12px;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;

  li {
    display: block;

    .image-wrapper {
      position: relative;
      border-radius: 8px;
      margin-bottom: 8px;
      box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;

      i {
        display: none;
        position: absolute;
        right: -12px;
        top: -12px;
        background-color: white;
        border-radius: 50%;
        padding: 2px;
      }

      img {
        display: block;
        width: 100%;
        max-width: 400px;
        object-fit: contain;
        border-radius: 8px;
      }
    }

    p {
      padding-left: 4px;
      margin-bottom: 0;
    }

    &.actived {
      .image-wrapper {
        border-radius: 10px;
        box-shadow: unset;
        padding: 1px;

        i {
          display: block;
        }
      }

      p {
        color: var(--v-primary-base);
        font-weight: 500;
      }
    }
  }
}

::v-deep .store-domain-input {
  .v-input__append-inner {
    margin-top: 9px;
    margin-right: 6px;
  }
}
::v-deep .v-chip.v-size--default {
  height: 40px;
}
</style>
